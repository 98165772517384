@media screen and (min-width: 768px) {

  /* VERSION NORMAL */
  /* CUSTOM OHIF SCROLLBAR */
  .ohif-scrollbar {
    scrollbar-color: #8e9da1 transparent !important;
    scrollbar-width: thin !important;
    overflow-y: auto !important;
  }

  .ohif-scrollbar-stable-gutter {
    scrollbar-gutter: stable !important;
  }

  .study-min-height {
    height: 100px !important;
  }

  .ohif-scrollbar:hover {
    overflow-y: auto !important;
  }

  .ohif-scrollbar::-webkit-scrollbar {
    width: 8px !important;
  }

  .ohif-scrollbar::-webkit-scrollbar-track {
    border-radius: 4px !important;
  }

  .ohif-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #041c4a !important;
  }

  .ohif-scrollbar::-webkit-scrollbar-thumb:window-inactive {
    background-color: #041c4a !important;
  }

  /* SCROLLBAR HORIZONTAL */
  .ohif-scrollbar2 {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    scrollbar-color: #8e9da1 transparent !important;
    scrollbar-width: thin !important;
    scrollbar-gutter: stable both-edges !important;
  }

  .ohif-scrollbar2::-webkit-scrollbar {
    height: 8px !important;
  }

  .ohif-scrollbar2::-webkit-scrollbar-thumb {
    background-color: #8e9da1 !important;
    border-radius: 4px !important;
  }

  .ohif-scrollbar2::-webkit-scrollbar-track {
    background: white !important;
  }

  .scroll-content {
    display: flex !important;
    flex-direction: row !important;
    min-height: 100px !important;
  }
}

@media screen and (max-width: 767px) {

  /* VERSION MÓVIL */
  /* CUSTOM OHIF SCROLLBAR */
  .ohif-scrollbar {
    scrollbar-color: #8e9da1 transparent !important;
    scrollbar-width: thin !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
  }

  .ohif-scrollbar-stable-gutter {
    scrollbar-gutter: stable both-edges !important;
  }

  .study-min-height {
    height: 100px !important;
  }

  .ohif-scrollbar:hover {
    overflow-y: hidden !important;
    overflow-x: auto !important;
  }

  .ohif-scrollbar::-webkit-scrollbar {
    width: 8px !important;
  }

  .ohif-scrollbar::-webkit-scrollbar-track {
    border-radius: 4px !important;
  }

  /* SCROLLBAR INVISIBLE - Si se necesita forzar invisibilidad, aún así quedará por debajo del "!important" anterior */
  .invisible-scrollbar {
    scrollbar-width: none !important;
  }

  .invisible-scrollbar::-webkit-scrollbar {
    display: none !important;
  }

  .invisible-scrollbar::-webkit-scrollbar-track {
    display: none !important;
  }

  .invisible-scrollbar::-webkit-scrollbar-thumb {
    display: none !important;
  }

  .invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {
    display: none !important;
  }

  /* SCROLLBAR HORIZONTAL */
  .ohif-scrollbar2 {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    scrollbar-color: #8e9da1 transparent !important;
    scrollbar-width: thin !important;
    scrollbar-gutter: stable both-edges !important;
  }

  .ohif-scrollbar2::-webkit-scrollbar {
    height: 8px !important;
  }

  .ohif-scrollbar2::-webkit-scrollbar-thumb {
    background-color: #8e9da1 !important;
    border-radius: 4px !important;
  }

  .ohif-scrollbar2::-webkit-scrollbar-track {
    background: white !important;
  }

  .scroll-content {
    display: flex !important;
    flex-direction: row !important;
    min-height: 100px !important;
  }
}
